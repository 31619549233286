._9iv6jh0 {
  position: relative;
}
._9iv6jh1 {
  position: relative;
}
._9iv6jh3 {
  background: var(--_5kd49o4);
}
._9iv6jh4 {
  background: var(--_5kd49o1s);
}
._9iv6jh5 {
  background: var(--_5kd49o18);
}
._9iv6jh6 {
  background: var(--_5kd49oe);
}
._9iv6jh7 {
  background: var(--_5kd49o2c);
}
._9iv6jh8 {
  background: var(--_5kd49oy);
}
._9iv6jh9 {
  position: relative;
  border-width: thin;
  border-style: solid;
  border-color: var(--_5kd49o1);
}
._9iv6jha {
  position: absolute;
  background: var(--_5kd49o2);
  top: 4px;
  width: 100%;
  height: 100%;
  left: -4px;
}
._9iv6jhc {
  background: var(--_5kd49o5);
}
._9iv6jhd {
  background: var(--_5kd49o1t);
}
._9iv6jhe {
  background: var(--_5kd49o19);
}
._9iv6jhf {
  background: var(--_5kd49of);
}
._9iv6jhg {
  background: var(--_5kd49o2d);
}
._9iv6jhh {
  background: var(--_5kd49oz);
}
._9iv6jhi {
  background-image: linear-gradient(45deg, var(--_5kd49o6) 25%, var(--_5kd49o5) 25%, var(--_5kd49o5) 50%, var(--_5kd49o6) 50%, var(--_5kd49o6) 75%, var(--_5kd49o5) 75%, var(--_5kd49o5) 100%);;
  background-size: 56.57px 56.57px;;
}