._1711g7s0 {
  display: flex;
  justify-content: ;
}
._1711g7s1 {
  justify-content: space-between;
}
._1711g7s2 {
  justify-content: center;
}
._1711g7s3 {
  justify-content: flex-start;
}
._1711g7s4 {
  justify-content: flex-end;
}
._1711g7s5 {
  align-items: between;
}
._1711g7s6 {
  align-items: around;
}
._1711g7s7 {
  align-items: center;
}
._1711g7s8 {
  align-items: flex-start;
}
._1711g7s9 {
  align-items: flex-end;
}
._1711g7sa {
  flex-direction: column;
}
._1711g7sb {
  gap: var(--_5kd49o2v);
}
._1711g7sc {
  gap: var(--_5kd49o2w);
}
._1711g7sd {
  gap: var(--_5kd49o2x);
}
._1711g7se {
  gap: var(--_5kd49o2y);
}
._1711g7sf {
  gap: var(--_5kd49o2z);
}