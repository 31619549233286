._1mp1nwq0 {
  font-family: var(--_5kd49o34);
  font-weight: var(--_5kd49o3f);
  line-height: var(--_5kd49o3i);
  color: var(--_5kd49o1);
  font-size: inherit;
}
._1mp1nwq1 {
  font-size: inherit;
}
._1mp1nwq2 {
  font-size: var(--_5kd49o38);
}
._1mp1nwq3 {
  font-size: var(--_5kd49o39);
}
._1mp1nwq4 {
  font-size: var(--_5kd49o3a);
}
._1mp1nwq5 {
  font-size: var(--_5kd49o3c);
}
._1mp1nwq6 {
  font-weight: var(--_5kd49o3h);
}
._1mp1nwq7 {
  font-style: italic;
}