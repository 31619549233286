:root {
  --_5kd49o0: transparent;
  --_5kd49o1: #000;
  --_5kd49o2: #fff;
  --_5kd49o3: ;
  --_5kd49o4: #f7fafc;
  --_5kd49o5: #edf2f7;
  --_5kd49o6: #e2e8f0;
  --_5kd49o7: #cbd5e0;
  --_5kd49o8: #a0aec0;
  --_5kd49o9: #718096;
  --_5kd49oa: #4a5568;
  --_5kd49ob: #2d3748;
  --_5kd49oc: #1a202c;
  --_5kd49od: ;
  --_5kd49oe: #fff5f5;
  --_5kd49of: #fed7d7;
  --_5kd49og: #feb2b2;
  --_5kd49oh: #fc8181;
  --_5kd49oi: #f56565;
  --_5kd49oj: #e53e3e;
  --_5kd49ok: #c53030;
  --_5kd49ol: #9b2c2c;
  --_5kd49om: #742a2a;
  --_5kd49on: ;
  --_5kd49oo: #fffaf0;
  --_5kd49op: #feebc8;
  --_5kd49oq: #fbd38d;
  --_5kd49or: #f6ad55;
  --_5kd49os: #ed8936;
  --_5kd49ot: #dd6b20;
  --_5kd49ou: #c05621;
  --_5kd49ov: #9c4221;
  --_5kd49ow: #7b341e;
  --_5kd49ox: ;
  --_5kd49oy: #fffff0;
  --_5kd49oz: #fefcbf;
  --_5kd49o10: #faf089;
  --_5kd49o11: #f6e05e;
  --_5kd49o12: #ecc94b;
  --_5kd49o13: #d69e2e;
  --_5kd49o14: #b7791f;
  --_5kd49o15: #975a16;
  --_5kd49o16: #744210;
  --_5kd49o17: ;
  --_5kd49o18: #f0fff4;
  --_5kd49o19: #c6f6d5;
  --_5kd49o1a: #9ae6b4;
  --_5kd49o1b: #68d391;
  --_5kd49o1c: #48bb78;
  --_5kd49o1d: #38a169;
  --_5kd49o1e: #2f855a;
  --_5kd49o1f: #276749;
  --_5kd49o1g: #22543d;
  --_5kd49o1h: ;
  --_5kd49o1i: #e6fffa;
  --_5kd49o1j: #b2f5ea;
  --_5kd49o1k: #81e6d9;
  --_5kd49o1l: #4fd1c5;
  --_5kd49o1m: #38b2ac;
  --_5kd49o1n: #319795;
  --_5kd49o1o: #2c7a7b;
  --_5kd49o1p: #285e61;
  --_5kd49o1q: #234e52;
  --_5kd49o1r: ;
  --_5kd49o1s: #ebf8ff;
  --_5kd49o1t: #bee3f8;
  --_5kd49o1u: #90cdf4;
  --_5kd49o1v: #63b3ed;
  --_5kd49o1w: #4299e1;
  --_5kd49o1x: #3182ce;
  --_5kd49o1y: #2b6cb0;
  --_5kd49o1z: #2c5282;
  --_5kd49o20: #2a4365;
  --_5kd49o21: ;
  --_5kd49o22: #ebf4ff;
  --_5kd49o23: #c3dafe;
  --_5kd49o24: #a3bffa;
  --_5kd49o25: #7f9cf5;
  --_5kd49o26: #667eea;
  --_5kd49o27: #5a67d8;
  --_5kd49o28: #4c51bf;
  --_5kd49o29: #434190;
  --_5kd49o2a: #3c366b;
  --_5kd49o2b: ;
  --_5kd49o2c: #faf5ff;
  --_5kd49o2d: #e9d8fd;
  --_5kd49o2e: #d6bcfa;
  --_5kd49o2f: #b794f4;
  --_5kd49o2g: #9f7aea;
  --_5kd49o2h: #805ad5;
  --_5kd49o2i: #6b46c1;
  --_5kd49o2j: #553c9a;
  --_5kd49o2k: #44337a;
  --_5kd49o2l: ;
  --_5kd49o2m: #fff5f7;
  --_5kd49o2n: #fed7e2;
  --_5kd49o2o: #fbb6ce;
  --_5kd49o2p: #f687b3;
  --_5kd49o2q: #ed64a6;
  --_5kd49o2r: #d53f8c;
  --_5kd49o2s: #b83280;
  --_5kd49o2t: #97266d;
  --_5kd49o2u: #702459;
  --_5kd49o2v: 0rem;
  --_5kd49o2w: 0.25rem;
  --_5kd49o2x: 0.5rem;
  --_5kd49o2y: 1rem;
  --_5kd49o2z: 2rem;
  --_5kd49o30: 4rem;
  --_5kd49o31: 8rem;
  --_5kd49o32: 16rem;
  --_5kd49o33: Barlow, sans-serif;
  --_5kd49o34: Barlow, sans-serif;
  --_5kd49o35: Barlow, sans-serif;
  --_5kd49o36: monospace;
  --_5kd49o37: 0.75rem;
  --_5kd49o38: 0.80rem;
  --_5kd49o39: 1rem;
  --_5kd49o3a: 1.25rem;
  --_5kd49o3b: 1.5rem;
  --_5kd49o3c: 2rem;
  --_5kd49o3d: 3rem;
  --_5kd49o3e: 4rem;
  --_5kd49o3f: 400;
  --_5kd49o3g: 500;
  --_5kd49o3h: 700;
  --_5kd49o3i: 1.5;
  --_5kd49o3j: 1.125;
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
html, body {
  font-family: var(--_5kd49o34);
  font-weight: var(--_5kd49o3f);
  font-size: var(--_5kd49o39);
}