._1s9tj570 {
  border-radius: 0.25rem;
  background: none;
  border: none;
  cursor: pointer;
  transition: background-color 100ms, color 100ms;
  border-width: thin;
  color: var(--_5kd49o1);
  border-bottom-width: 2px;
}
._1s9tj570:active {
  border-top-width: 2px;
  border-bottom-width: 1px;
}
._1s9tj571:hover {
  background: var(--_5kd49o5);
}
._1s9tj572 {
  color: var(--_5kd49o1);
  border-style: solid;
  border-color: var(--_5kd49o20);
  background: var(--_5kd49o1t);
}
._1s9tj572:hover {
  background: var(--_5kd49o1u);
}
._1s9tj572:active {
  border-top-width: 2px;
  border-bottom-width: 1px;
}
._1s9tj573 {
  color: var(--_5kd49o1);
  background: var(--_5kd49o5);
  border-style: solid;
  border-color: var(--_5kd49oc);
}
._1s9tj573:hover {
  background: var(--_5kd49o6);
}
._1s9tj574 {
  color: var(--_5kd49o1);
  background: var(--_5kd49o19);
  border-style: solid;
  border-color: var(--_5kd49o1g);
}
._1s9tj574:hover {
  background: var(--_5kd49o1a);
}
._1s9tj575 {
  color: var(--_5kd49o1);
  background: var(--_5kd49o1j);
  border-style: solid;
  border-color: var(--_5kd49o1q);
}
._1s9tj575:hover {
  background: var(--_5kd49o1l);
}
._1s9tj576 {
  color: var(--_5kd49o1);
  background: var(--_5kd49o10);
  border-style: solid;
  border-color: var(--_5kd49o16);
}
._1s9tj576:hover {
  background: var(--_5kd49o11);
}
._1s9tj577 {
  color: var(--_5kd49o1);
  background: var(--_5kd49of);
  border-style: solid;
  border-color: var(--_5kd49om);
}
._1s9tj577:hover {
  background: var(--_5kd49og);
}
._1s9tj578 {
  color: var(--_5kd49o1);
  background: var(--_5kd49o0);
}
._1s9tj578:hover {
  background: rgba(0,0,0,0.05);
}
._1s9tj579 {
  padding: var(--_5kd49o2w);
  font-size: var(--_5kd49o38);
}
._1s9tj57a {
  padding: var(--_5kd49o2x);
  font-size: var(--_5kd49o39);
}
._1s9tj57b {
  padding: var(--_5kd49o2y);
  font-size: var(--_5kd49o3a);
}
._1s9tj57c {
  padding: var(--_5kd49o2z);
  font-size: var(--_5kd49o3c);
}
._1s9tj57d {
  border-radius: 999px;
}
._1s9tj57e {
  border-width: thin;
  border-style: solid;
  background: var(--_5kd49o0);
}
._1s9tj57e:hover {
  color: var(--_5kd49o1);
}
._1s9tj57f {
  border-color: var(--_5kd49o8);
  background: var(--_5kd49o0);
}
._1s9tj57f:hover {
  color: var(--_5kd49o1);
}
._1s9tj57g {
  border-color: var(--_5kd49o1y);
  color: var(--_5kd49o1z);
  background: var(--_5kd49o0);
}
._1s9tj57g:hover {
  color: var(--_5kd49o1);
}
._1s9tj57h {
  border-color: var(--_5kd49oa);
  color: var(--_5kd49ob);
  background: var(--_5kd49o0);
}
._1s9tj57h:hover {
  color: var(--_5kd49o1);
}
._1s9tj57i {
  border-color: var(--_5kd49o1e);
  color: var(--_5kd49o1f);
  background: var(--_5kd49o0);
}
._1s9tj57i:hover {
  color: var(--_5kd49o1);
}
._1s9tj57j {
  border-color: var(--_5kd49o1o);
  color: var(--_5kd49o1p);
  background: var(--_5kd49o0);
}
._1s9tj57j:hover {
  color: var(--_5kd49o1);
}
._1s9tj57k {
  border-color: var(--_5kd49o14);
  color: var(--_5kd49o15);
  background: var(--_5kd49o0);
}
._1s9tj57k:hover {
  color: var(--_5kd49o1);
}
._1s9tj57l {
  border-color: var(--_5kd49ok);
  color: var(--_5kd49ol);
  background: var(--_5kd49o0);
}
._1s9tj57l:hover {
  color: var(--_5kd49o1);
}
._1s9tj57m {
  border-color: var(--_5kd49oa);
  color: var(--_5kd49ob);
  background: var(--_5kd49o0);
}
._1s9tj57m:hover {
  color: var(--_5kd49o1);
}